export default [
  {
    text: "Documentation",
    path: "/docs",
    icon: "/assets/home.svg"
  },
  // {
  //   text: "Setting",
  //   icon: "preferences",
  //   items: [
  //     {
  //       text: "Database Config",
  //       path: "/WA/Setting/DBConfig"
  //     },
  //     {
  //       text: "Database Connection",
  //       path: "/WA/Setting/DBConnection"
  //     },
  //   ]
  // },
  {
    text: "Privilege",
    icon: "key",
    items: [
      {
        text: "Customer",
        path: "/WA/Customer",
      },
      {
        text: "License",
        path: "/License",
      },
      {
        text: "User",
        path: "/WA/User",
      },
      {
        text: "Group",
        path: "/WA/Group",
      },
    ]
  },

  {
    text: "Device",
    path: "/WA/Device",
      icon: "/assets/phone.svg"
  },
  // {
  //   text: "Contact",
  //   path: "/WA/Contact",
  //     icon: "/assets/contact-book.svg"
  // },
  {
    text: "Outbox",
    path: "/WA/Outbox",
      icon: "/assets/envelope.svg"
  },
  // {
  //   text: "Balance",
  //   path: "/WA/Balance",
  //     icon: "/assets/dollar.svg"
  // },
  // {
  //   text: "Token",
  //   path: "/WA/Token",
  //     icon: "/assets/lock-closed.svg"
  // },
  // {
  //   text: "Grab Contact",
  //   path: "/WA/GrabContact",
  //     icon: "/assets/grab.svg"
  // },
  {
    text: "Contact Group",
    path: "/WA/ContactGroup",
    icon: "/assets/wifi.svg"
  },
  {
    text: "Contact",
    path: "/WA/Contact",
    icon: "/assets/wifi.svg"
  },
  {
    text: "Blast",
    path: "/WA/Blast",
    icon: "/assets/wifi.svg"
  },
  // {
  //   text: "Media",
  //   path: "/Media",
  //   icon: "/assets/wifi.svg"
  // },
  // {
  //   text: "Bot",
  //   path: "/WA/Bot",
  //     icon: "/assets/chatbot.svg"
  // },
  // {
  //   text: "Role",
  //   path: "/WA/Role",
  //     icon: "/assets/hastag.svg"
  // },
  // {
  //   text: "Menu",
  //   path: "/WA/Menu",
  //     icon: "/assets/hastag.svg"
  // },

];
