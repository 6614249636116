<template>
  <div style="background-color: white; min-height: 80vh; margin: 20px; padding:20px">
    <DxResponsiveBox :screen-by-width="screen" single-column-screen="sm">
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxCol :ratio="3" />
      <DxCol :ratio="2"/>
      <DxItem>
        <DxLocation :row="0" :col="0" :colspan="3" screen="lg" />
        <div class="header item">
          <h3>Dokumentasi API</h3>
        </div>
      </DxItem>

      <!-- Get Started -->
      <DxItem>
        <DxLocation :row="1" :col="0"/>
        <div class="content item">
           <div class="overflow-hidden content-section" id="content-get-started">
            <h1>Memulai</h1>
            <p>Web Message Service (WMS) mendukung pengiriman pesan ke kontak lain.</p>
            <p>
               Untuk menggunakan API, anda harus mempunyai <strong>API key</strong>.
               Mohon untuk menghubungi
               <a href="mailto:herris.pm@gmail.com">herris.pm@gmail.com</a> atau WA: <a href="https://wa.me/6282370832118">082370832118</a>
               untuk mendapatkan API key.
            </p>
        </div>
        </div>
      </DxItem>
      <DxItem>
        <DxLocation :row="1" :col="1"/>
          <pre style="padding: 10px;">API Endpoint

https://api.wms.techluxid.com
Version  : 0.1.0
Author   : Herris Suhendra
Email    : herris.pm@gmail.com
Whatsapp : 082370832118 | 087722777178
</pre>
      </DxItem>

      <!-- Get Devices -->
      <DxItem>
        <DxLocation :row="2" :col="0"/>
        <div class="content item">
           <div class="overflow-hidden content-section" id="content-get-started">
            <h1>Get Devices</h1>
        </div>
        </div>
      </DxItem>
      <DxItem>
        <DxLocation :row="2" :col="1"/>
          <pre style="padding: 10px;">
<code class="bash">
# Get Devices
curl \
-X GET http://api.wms.com/Device/List \
--header 'authorization=your_api_key' \
-F 'Offset=0' \
-F 'Limit=50'</code>
          </pre>
      </DxItem>

      <!-- Send Text -->
      <DxItem>
        <DxLocation :row="3" :col="0"/>
        <div class="content item">
           <div class="overflow-hidden content-section" id="content-get-started">
            <h1>Send Text</h1>
            <p>Untuk mengirimkan pesan berupa teks.</p>
        </div>
        </div>
      </DxItem>
      <DxItem>
        <DxLocation :row="3" :col="1"/>
          <pre style="padding: 10px;">
<code class="bash">
# Send Text
curl \
-X POST http://api.wms.com/API/Text/Send \
--header 'authorization=your_api_key' \
-F 'Offset=0' \
-F 'Limit=50'</code>
          </pre>
      </DxItem>
    </DxResponsiveBox>
  </div>
</template>

<script>
import {
  DxResponsiveBox, DxItem, DxLocation, DxCol, DxRow,
} from 'devextreme-vue/responsive-box';
export default {
  components: {
    DxResponsiveBox, DxItem, DxLocation, DxCol, DxRow,
  },
  data() {
    return {
    };
  },
  methods: {

  },
  mounted() {

  }
};
</script>